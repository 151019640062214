// ./APIs/Authentication.js
// This is just used to check if a token key set in LocalStorage for now.   When the authentication microservice is better built out we will check for more.

import history from "./History";
import axios from "axios";
import * as moment from "moment";
import { promised } from "q";

const Authentication = {
  
  login(email, password) {
    return new Promise(function (resolve, reject) {
      axios
        .post("https://id2.shippersedge.com/carrier/login", {}, {
          auth: {
            username: email,
            password: password
          }
        })
        .then(response => {
          // console.log("Successful Login! (Authentication/loginGo)", response);
          if (response.data.results.length > 1) {
            localStorage.setItem("multiCarrierWelcomeShowHide", "show");
          } else {
            localStorage.setItem("multiCarrierWelcomeShowHide", "hide");
          }
          let currentUser = response.data.results[0];
          let allUserData = response.data.results;
          // console.log(currentUser)
          localStorage.setItem("id", currentUser.id);
          localStorage.setItem("carrierId", currentUser.carrierId);
          localStorage.setItem("name", currentUser.name);
          localStorage.setItem("carrierName", currentUser.carrierName);
          localStorage.setItem("phone", currentUser.phone);
          localStorage.setItem("emailAddress", currentUser.email);
          // localStorage.setItem("secret", response.data.token);
          localStorage.setItem("token", currentUser.id);
          localStorage.setItem("clientName", currentUser.clientName);
          localStorage.setItem("carrierClientId", currentUser.clientCode);
          localStorage.setItem("clientLogo", currentUser.clientLogo);
          localStorage.setItem("userData", JSON.stringify(currentUser));
          localStorage.setItem("userData2", JSON.stringify(allUserData));
          localStorage.setItem("multiCarrierSupport", "yes");
          resolve(response);
          localStorage.setItem("loginDateTime", moment().format("YYYY-MM-DD"));
        })
        .catch(error => {
          if (error) {
            // console.log("Login error, getLoginGo2, status:", error.response.status);
            reject(false);
          }
        });
      return promised;
    });
  },

  getUserData() {
    if (JSON.parse(localStorage.getItem("userData2"))) {
      return JSON.parse(localStorage.getItem("userData2"))
    } else {
      console.warn('Authentication.getUserData failed')
      return false
    }
  },
  
  isLoggedIn() {
    let lcToken = localStorage.getItem("token");
    if (lcToken) {
      if (
        history.location.pathname === "/Login" ||
        history.location.pathname === "/"
      ) {
        history.push("/Dashboard");
      }
    } else {
      console.warn("token NOT present, redirect to login");
      history.push("/Login");
    }
    let lastLoginDate = localStorage.getItem("loginDateTime");
    let diffDays = moment().diff(lastLoginDate, "days");
    let multiCarrierSupport =  localStorage.getItem("multiCarrierSupport")
    //diffDays will turn into NaN if it is corrupted or otherwise wonky so need to test for NaN conditons.
    if ((diffDays > 7 || lastLoginDate === null || isNaN(diffDays)) || multiCarrierSupport === null) {
      console.log("Login Expired.");
      localStorage.setItem(
        "forceLogoutReason",
        "Your login session has expired, please login again."
      );
      localStorage.clear();
      history.push("/Login");
    }
  },

  profileUpdate(profileUpdate) {
    return new Promise(function (resolve, reject) {
      // console.log("Authentication.js, profileUpdate,", profileUpdate.values());
      axios
        .post(
          "https://schuff.shippersedge.com/public/carrier/updateProfile.cfm?wsdl",
          profileUpdate
        )
        .then(response => {
          // console.log("wat", profileUpdate.get("email"));
          localStorage.setItem("name", profileUpdate.get("name"));
          localStorage.setItem("email", profileUpdate.get("email"));
          localStorage.setItem("phone", profileUpdate.get("phone"));
          resolve(response);
        })
        .catch(error => {
          if (error) {
            reject("Authenticat.js, profileUpdate, error", error);
          }
        });
      return promised;
    });
  },

  getID() {
    return localStorage.getItem("id");
  },

  getEmailAddress() {
    return localStorage.getItem("emailAddress");
  },

  getName() {
    return localStorage.getItem("name");
  },

  getPhone() {
    return localStorage.getItem("phone");
  },

  logOut() {
    localStorage.clear();
    history.push("/Login");
  }
};

export default Authentication;
