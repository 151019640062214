import React from 'react'

const DriverPhone = ({ shipmentObjUpdateEvent, value, userData, hilightHide }) => {
    let placeholder = "For Phone Tracking"
    if (userData.hasOwnProperty("gpsTracking") && userData.gpsTracking !== null) {
        placeholder = "For Phone Tracking"
    }
    let className = "col-2"
    className += " " + hilightHide

    if (userData.clientCode === "Schuff" || userData.clientCode === "graywolf" || userData.clientCode === "bankersteel") {
        return (
            <div className={className}>
                <label for="driverPhone" class="form-label">Driver Phone:</label>
                <input type="text" name="loadDetails.driverPhone" id="driverPhone" class="form-control" placeholder={placeholder} aria-describedby="Driver Phone Number" value={value} onChange={(event) => shipmentObjUpdateEvent(event)}></input>
            </div>
        )
    }
    else {

        return null
    }
}

export default DriverPhone
